import { Route } from 'react-router-dom';
import { OrderSpecialProjectsModal } from './OrderSpecialProjectsModal';
import React from 'react';
import { SpecialProjectsPropsFragment, useSpecialProjectsQuery } from '../../../schema';
import { toast } from 'react-toastify';
import { DataConnection } from '../../../hooks';
import { Loading } from '../../../ui/Loading';

export const OrderSpecialProjectsPage = () => {
  const { data, loading, error } = useSpecialProjectsQuery({
    variables: {
      includeClosedOrCancelled: false,
    },
  });

  if (error) {
    toast.error('Ошибка загрузки спецпроектов');
    return null;
  }

  const entities = data?.specialProjects as DataConnection<SpecialProjectsPropsFragment>;

  const specialProjects =
    entities?.nodes
      ?.filter((t) => t !== undefined && t !== null)
      .map((t) => t as SpecialProjectsPropsFragment) || [];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Route
        path={'/special-projects/order'}
        render={() => <OrderSpecialProjectsModal specialProjects={specialProjects} />}
      />
    </>
  );
};
