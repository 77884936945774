import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AccountDocument,
  OneTimeNeed,
  ProjectExpensesDocument,
  useSendOneTimeNeedReportMutation,
  useSendProjectExpenseReportMutation,
  WardByIdDocument,
} from 'schema';
import { MutableButton } from 'ui/MutableButton';
import { toast } from 'react-toastify';
import { IAccountProps } from 'pages/components';
import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { useMeContext } from '../../../../components';
import { useHistory, useParams } from 'react-router-dom';

export const CloseAccount = ({ account, need, period, project }: IAccountProps) => {
  const { __typename } = account!;
  const { user } = useMeContext();
  const { id, needId, periodId } = useParams<{ id: string; needId: string; periodId: string }>();
  const history = useHistory();

  const [
    sendProjectExpenseReport,
    {
      loading: loadingProjectExpenseAccount,
      error: errorProjectExpenseAccount,
      data: dataProjectExpenseAccount,
    },
  ] = useSendProjectExpenseReportMutation();

  const [
    sendOneTimeNeedReport,
    {
      loading: loadingOneTimeNeedAccount,
      error: errorOneTimeNeedAccount,
      data: dataOneTimeNeedAccount,
    },
  ] = useSendOneTimeNeedReportMutation();

  const refetchQueries = useMemo(() => {
    return [
      {
        query: AccountDocument,
        variables: { id: account!.id },
      },
      {
        query: WardByIdDocument,
        variables: { id, permission: user?.permissions?.wards.edit },
      },
    ];
  }, [id, account, user?.permissions]);

  const [isClosed, setIsClosed] = useState<boolean>(false);

  useEffect(() => {
    if (__typename === 'ProjectExpenseAccount') {
      const closed = !project?.reportSentAt && project?.documents?.length;

      setIsClosed(Boolean(closed));
    } else if (__typename === 'OneTimeNeedAccount') {
      const closed = !(need as OneTimeNeed).reportSent;

      setIsClosed(Boolean(closed));
    } else if (__typename === 'RegularNeedPeriodAccount') {
      const closed = !period?.reportSentAt;
      setIsClosed(Boolean(closed));
    }
  }, [account, __typename, need, period, project]);

  const onClick = useCallback(async () => {
    if (__typename === 'ProjectExpenseAccount') {
      try {
        await sendProjectExpenseReport({
          refetchQueries: [
            {
              query: ProjectExpensesDocument,
              variables: { permission: user?.permissions.project.manageExpenses },
            },
          ],
          variables: { input: { id: project?.id } },
          awaitRefetchQueries: true,
        });
      } catch {
        errorProjectExpenseAccount && toast.error('Ошибка отправки отчета');
        return { [FORM_ERROR]: 'Ошибка отправки' } as SubmissionErrors;
      }
    } else if (__typename === 'RegularNeedPeriodAccount') {
      history.push(`/wards/manage/${id}/needs/${needId}/period/${periodId}/send-report`);
    } else if (__typename === 'OneTimeNeedAccount') {
      try {
        await sendOneTimeNeedReport({
          refetchQueries,
          variables: { input: { id: need?.id } },
          awaitRefetchQueries: true,
        });
      } catch {
        errorOneTimeNeedAccount && toast.error('Ошибка отправки отчета');
        return { [FORM_ERROR]: 'Ошибка отправки' } as SubmissionErrors;
      }
    }
  }, [
    __typename,
    sendProjectExpenseReport,
    user,
    project?.id,
    errorProjectExpenseAccount,
    refetchQueries,
    sendOneTimeNeedReport,
    need?.id,
    errorOneTimeNeedAccount,
    needId,
    periodId,
    history,
    id,
  ]);

  useEffect(() => {
    if (dataOneTimeNeedAccount || dataProjectExpenseAccount) {
      toast.success('Отчет успешно отправлен');
    }
  });

  if (errorProjectExpenseAccount || errorOneTimeNeedAccount) {
    toast.error('Ошибка отправки отчета');
  }

  return (
    <>
      <MutableButton
        onClick={onClick}
        loading={loadingProjectExpenseAccount || loadingOneTimeNeedAccount}
        disabled={!isClosed}
        color="primary"
        variant="contained"
      >
        Отправить отчёт
      </MutableButton>
    </>
  );
};
