import { useBackRoute } from '../../../../hooks';
import {
  RegularNeedPeriodByIdDocument,
  SendRegularNeedPeriodReportInput,
  useSendRegularNeedPeriodReportMutation,
} from '../../../../schema';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const initialValues: SendRegularNeedPeriodReportInput = {
  id: '',
  useFirstDocumentImageForNotification: false,
};

export const useSendReportRegularPeriodForm = () => {
  const { onCancel } = useBackRoute();
  const { periodId } = useParams<{ periodId: string }>();
  const [
    sendRegularNeedPeriodReport,
    { loading, error, data },
  ] = useSendRegularNeedPeriodReportMutation();
  const onSubmit = useCallback(
    async (values: SendRegularNeedPeriodReportInput) => {
      try {
        return await sendRegularNeedPeriodReport({
          variables: {
            input: {
              id: periodId,
              useFirstDocumentImageForNotification: values.useFirstDocumentImageForNotification,
            },
          },
          refetchQueries: [{ query: RegularNeedPeriodByIdDocument, variables: { id: periodId } }],
          awaitRefetchQueries: true,
        });
      } catch {
        return error && toast.error('Ошибка отправки отчета');
      }
    },
    [error, periodId, sendRegularNeedPeriodReport]
  );

  useEffect(() => {
    if (data !== undefined) {
      toast.success('Отчет успешно отправлен');
      onCancel();
    }
  }, [data, onCancel]);

  return {
    onSubmit,
    onCancel,
    loading,
    initialValues,
  };
};
