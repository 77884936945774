import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { MutableButton } from '../../../../../ui/MutableButton';

export const SendReportSpecialProjectButton = () => {
  const history = useHistory();
  const { id } = useParams();

  const handleClick = () => {
    history.push(`/special-projects/manage/${id}/send-report`);
  };

  return (
    <MutableButton onClick={handleClick} loading={false}>
      Отправить отчёт
    </MutableButton>
  );
};
