import { useBackRoute } from '../../../../../hooks';
import {
  SendSpecialProjectReportInput,
  SpecialProjectByIdDocument,
  useSendReportSpecialProjectReportMutation,
} from '../../../../../schema';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const initialValues: SendSpecialProjectReportInput = {
  id: '',
  useFirstDocumentImageForNotification: false,
};

export const useSendReportSpecialProjectForm = () => {
  const { onCancel } = useBackRoute();
  const { id } = useParams();
  const [sendReport, { loading, error, data }] = useSendReportSpecialProjectReportMutation();

  const onSubmit = useCallback(
    async (values: SendSpecialProjectReportInput) => {
      try {
        return await sendReport({
          variables: {
            input: {
              id,
              useFirstDocumentImageForNotification: values.useFirstDocumentImageForNotification,
            },
          },
          refetchQueries: [{ query: SpecialProjectByIdDocument, variables: { id } }],
        });
      } catch {
        return error && toast.error('Ошибка отправки отчета');
      }
    },
    [error, id, sendReport]
  );

  useEffect(() => {
    if (data?.sendSpecialProjectReport.specialProject) {
      toast.success('Отчет успешно отправлен');
      onCancel();
    }
  }, [data, onCancel]);

  return {
    onSubmit,
    onCancel,
    loading,
    initialValues,
  };
};
