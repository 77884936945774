import React, { useMemo } from 'react';
import { Card, CardActions, CardContent, Avatar, Divider, CardHeader } from '@material-ui/core';
import { Route } from 'react-router-dom';
import { SpecialProjectActionButtons, SpecialProjectDocuments } from './components';
import { useStyles } from './useStyles';
import { useMeContext } from 'components';
import { LayoutPageGrid } from 'ui/LayoutGrid';
import { PropertiesTable, PropertiesTableRow } from 'ui/PropertiesTable';
import { InvisibleCard } from 'ui/InvisibleCard';
import { SpecialProjectsPropsFragment } from '../../../schema';
import { toDateTime } from '../../../helpers';
import { CancelSpecialProjectModal } from './components/CancelSpecialProjectButton/CancelSpecialProjectModal';
import { SendReportModal } from './components/SendReportSpecialProjectModal';

type SpecialProjectsManagePageProps = {
  specialProject: SpecialProjectsPropsFragment;
};

export const SpecialProjectManagePage = ({ specialProject }: SpecialProjectsManagePageProps) => {
  const classes = useStyles();
  const {
    title,
    end,
    start,
    city,
    account,
    description,
    address,
    campaign,
    categories,
    feedImage,
  } = specialProject;

  const { user } = useMeContext();

  const editSpecialProject = user?.permissions.specialProjects.manage;
  const accountUrl = `/account/${account.id}`;

  const startDate = useMemo<string>(() => {
    const date = new Date(start as string);
    return toDateTime(date);
  }, [start]);

  const endDate = useMemo<string | undefined>(() => {
    if (end) {
      const date = new Date(end as string);
      return toDateTime(date);
    }
    return;
  }, [end]);

  return (
    <>
      <LayoutPageGrid title={title}>
        <Card>
          <CardContent>
            <InvisibleCard>
              <CardContent className={classes.content}>
                <Avatar src={feedImage?.url} className={classes.avatar} />
              </CardContent>
              {editSpecialProject && (
                <CardActions className={classes.buttons}>
                  <SpecialProjectActionButtons specialProject={specialProject} />
                </CardActions>
              )}
              <CardContent className={classes.content}>
                <Divider className={classes.divider} />
                <PropertiesTable>
                  <PropertiesTableRow caption="Название проекта" value={title} />
                  <PropertiesTableRow caption="Описание проекта" value={description} />
                  {campaign && <PropertiesTableRow caption="Кампания" value={campaign} />}
                  {categories && (
                    <PropertiesTableRow
                      caption="Категории"
                      value={categories.map(({ name }) => name).join(', ')}
                    />
                  )}
                  {address && <PropertiesTableRow caption="Адрес" value={address.fullAddress} />}
                  {city && <PropertiesTableRow caption="Город" value={city} />}
                  <PropertiesTableRow caption="Дата начала" value={startDate} />
                  <PropertiesTableRow
                    caption="Дата завершения "
                    value={end ? endDate : 'Не задана'}
                  />
                  <PropertiesTableRow
                    caption="Требуемая сумма"
                    value={
                      account.__typename === 'SpecialProjectAccount'
                        ? account.requiredAmount
                        : 'Без ограничений'
                    }
                    isAmount
                  />

                  <PropertiesTableRow
                    caption="Собранная сумма"
                    link={accountUrl}
                    value={account.amount}
                    isAmount
                  />
                </PropertiesTable>

                <Divider className={classes.divider} />
              </CardContent>
            </InvisibleCard>
          </CardContent>
        </Card>

        <Card>
          <CardHeader title="Документы" />
          <CardContent>
            <SpecialProjectDocuments specialProject={specialProject} />
          </CardContent>
        </Card>
      </LayoutPageGrid>

      <Route path="/special-projects/manage/:id/cancel" component={CancelSpecialProjectModal} />
      <Route path="/special-projects/manage/:id/send-report" component={SendReportModal} />
    </>
  );
};
