import React from 'react';
import { useParams, Switch } from 'react-router-dom';
import { useWardByIdQuery } from 'schema/serverTypes';
import { RouteWithLayout, useMeContext } from 'components';
import { Layout } from 'layouts';
import { EditWardPage } from '../EditWardPage';
import { WardNeedPage } from '../WardNeedPage';
import { WardDocumentsPage } from '../WardDocumentsPage';
import { ViewWardPage } from '../ViewWardPage';
import { toast } from 'react-toastify';
import { Loading } from 'ui/Loading';
import { RegularNeedPeriodPage } from '../WardNeedPage/components/RegularNeedPeriodPage';

export const WardContainer = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useMeContext();

  const { data, loading, error } = useWardByIdQuery({
    variables: {
      id,
      permission: user?.permissions?.accounts?.viewDepositAccounts ?? false,
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    toast.error('Ошибка загрузки подопечного');
    return null;
  }

  const ward = data?.wardById;

  if (ward === null) {
    toast.warn('Подопечный не найден');
    return null;
  }

  return (
    <Switch>
      <RouteWithLayout
        path="/wards/manage/:id/edit"
        component={EditWardPage}
        ward={ward}
        layout={Layout}
      />
      <RouteWithLayout
        exact
        path="/wards/manage/:id/documents"
        component={WardDocumentsPage}
        ward={ward}
        layout={Layout}
      />

      <RouteWithLayout
        exact
        path="/wards/manage/:id/needs/:needId/information"
        ward={ward}
        component={WardNeedPage}
        layout={Layout}
      />
      <RouteWithLayout
        path="/wards/manage/:id/needs/:needId/period/:periodId"
        ward={ward}
        component={RegularNeedPeriodPage}
        layout={Layout}
      />

      <RouteWithLayout
        path="/wards/manage/:id"
        ward={ward}
        component={ViewWardPage}
        layout={Layout}
      />
    </Switch>
  );
};
